.support-career-wrapper {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  max-width: 1061px;
  margin: 0 auto;
  padding-bottom: 2rem;
  .tabs {
    display: flex;
    gap: 2rem;
    justify-content: center;
    align-items: center;
    button {
      height: 100%;
      cursor: pointer;
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;
      color: #111111;
      background-color: unset;
      border-bottom: 3px solid transparent !important;
      &.active {
        border-color: #03a9f4 !important;
        font-weight: bold;
      }
    }
  }
  .ask-support {
    font-weight: bold;
    font-size: 24px;
    line-height: 35px;
    color: #111111;
    margin: 0;
  }
  .new-project-btn {
    cursor: pointer;
    a {
      color: unset !important;
      text-decoration: unset !important;
    }
  }
  .actions {
    margin-top: 2rem;
    display: flex;
    justify-content: flex-end;
    button {
      display: flex;
      align-items: center;
      gap: 10px;
      cursor: pointer;
      color: #fff;
      background: #03a9f4;
      border-radius: 8px;
      padding: 0px 16px;
      height: 32px;
      &:disabled {
        background: #e0e0e0;
        cursor: not-allowed;
      }
    }
  }
  .form-wrapper {
    padding: 0 48px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
    row-gap: 3rem;
    form {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
    img {
      height: fit-content;
    }
    > * {
      flex: 1;
    }
    .error-message {
      font-size: 12px;
      color: red;
    }
    .ant-form-item {
      margin: 0;
    }
    .ant-form-item-label {
      line-height: unset;
      text-align: right !important;
    }
  }
}
.skills-wrapper {
  padding: 0 3rem;
  padding-bottom: 1rem;
  position: relative;
  img {
    width: 450px;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-30%);
  }
  .p-financ-content {
    font-weight: bold;
  }
  .p-heading {
    font-size: 24px;
    line-height: 35px;
    text-align: start;
  }
}
.support-career-wrapper {
  .form-header {
    position: relative;
    justify-content: center;
    width: 100%;
    display: flex;
    gap: 1rem;
    align-items: center;
    .back-btn {
      position: absolute;
      right: 50px;
      display: flex;
      align-items: center;
      gap: 10px;
  
      width: 30px;
      height: 30px;
      background-color: #d9d9d9;
      display: grid;
      place-items: center;
      border-radius: 50%;
      padding-top: 5px;
    }
  }
}
@media screen and (max-width: 768px) {
  .form-header {
    .back-btn {
      position: static;
    }
  }
  .support-career-wrapper {
    .form-wrapper {
      padding: 0 1rem;
    }
  }
  .skills-wrapper {
    padding: 0 1rem;
    > div:first-child {
      display: flex;
      flex-direction: column;
    }
    img {
      align-self: center;
      margin: 2rem 0;
      order: 2;
      width: 90%;
      position: unset;
      transform: unset;
    }
  }
}
