.statistics {
  display: flex;
  flex-direction: column;
  margin: 15px;
  margin-top: 50px;
  background-color: white;
  border-radius: 10px;
  padding-bottom: 30px;
  text-align: center;
  align-items: center;
  float: right;
  box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.09);
}
.user-stc-button {
  color: #ffff !important;
  background: #69696978 !important;
  width: 200px !important;
}
.user-stc-header {
  width: 100%;
  background: #40a9ff;
  height: 80px;
  padding-top: 20px;
  font-weight: 700;
  font-size: 16px;
  color: #cce3f5;
  margin-bottom: 15px;
}
.user-stc-result {
  font-size: 16px;
  font-weight: 900;
  padding-top: 20px;
}
.weekly-growth {
  background-color: white;
  border-radius: 5%;
  position: absolute;
  right: 3%;
  top: 60px;
  width: 550px;
}
.user-percentages {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.user-statistics {
  display: flex;
  flex-direction: row;
  // justify-content: center;
  padding-right: 25px;
}
.ant-btn:hover,
.ant-btn:focus {
  color: #40a9ff;
  background-color: #fff;
  border-color: #69696978;
}

.statistic-search-form {
  background: #fff;
  border-radius: 16px;
  margin-top: 50px;
  box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.07);
  padding: 30px !important;
}

.export-user {
  color: #1890ff !important;
  background: #fff !important;
  width: 100% !important;
  cursor: pointer;
}
