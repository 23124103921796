.BibanVisitor__wrapper {
  max-width: 768px;
  width: 100%;
  margin-inline: auto;
  margin-block: calc(96px + 1rem);
  padding-bottom: 5rem;
  button {
    border-radius: 8px;
  }
  .ant-col.ant-form-item-label {
    display: flex;
    justify-content: flex-start;
    label {
      text-align: start;
      width: 100%;
    }
  }
  .ant-select-selection {
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    padding-inline: 15px;
  }
  .ant-select-selection__rendered {
    margin: 0;
  }
  .ant-form-item {
    display: flex;
    flex-direction: column;
  }
  .ant-form-item-label {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
    color: #111111;
  }
  .BibanVisitor__welcome-wrapper {
    margin-inline: 1rem;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    background: #ffffff;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 48px;
    padding: 96px 76px;
    h2 {
      text-align: center;
      font-style: normal;
      font-weight: 400;
      font-size: 32px;
      line-height: 47px;
      color: #000000;
    }
    .brand {
      width: 100%;
      max-width: 221.86px;
      max-height: 64.83px;
    }
    .image {
      width: 100%;
      max-width: 472px;
      max-height: 328px;
    }
    button {
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 4px;
      color: #fff;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      height: 40px;
      padding: 4px 16px;
      background: #03a9f4;
      border-radius: 8px;
    }
    @media screen and (max-width: 768px) {
      padding: 32px 12px;
      h2 {
        font-size: 24px;
        line-height: 35px;
      }
    }
  }
  .BibanVisitor__search-form-wrapper {
    margin-inline: 1rem;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    background: #ffffff;

    display: flex;
    flex-direction: column;
    .BibanVisitor__search-form__header {
      display: flex;
      justify-content: center;
      padding: 48px 10px;
      border-bottom: 1px solid #e9e9e9;
    }
    .BibanVisitor__search-form__body {
      margin-top: 16px;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      gap: 32px;
      padding-bottom: 2rem;
      h3 {
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 35px;
        color: #000000;

        padding-inline: 16px;
      }
      form.ant-form {
        max-width: 470px;
        padding-inline: 16px;
        margin-inline: auto;
        display: flex;
        flex-direction: column;
        width: 100%;
        .BibanVisitor__search-form__body__button {
          display: flex;
          align-items: center;
          gap: 4px;
          span {
            padding-bottom: 5px;
          }
        }
      }
    }
  }
  .BibanVisitor__matched-users-wrapper {
    margin-inline: 1rem;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    background: #ffffff;

    display: flex;
    flex-direction: column;
    .BibanVisitor__matched-users__header {
      display: flex;
      justify-content: center;
      padding: 48px 10px;
      border-bottom: 1px solid #e9e9e9;
    }
    .BibanVisitor__matched-users__body {
      margin-top: 32px;
      max-width: 690px;
      width: 100%;
      margin-inline: auto;

      .total {
        display: flex;
        justify-content: center;
        text-align: center;
        padding-inline: 16px;
        color: #595959;
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 47px;
      }
      hr {
        opacity: 0.5;
        padding-inline: 16px;
        margin-bottom: 16px;
        margin-top: 32px;
        border: 0;
        border-bottom: 1px solid #bababa;
        background: unset;
        color: unset;
        outline: 0;
        width: 91%;
      }
      .BibanVisitor__matched-users__body__items {
        padding: 16px;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
        gap: 32px;
        .BibanVisitor__matched-users__body__items__item {
          padding: 16px;
          min-height: 363px;
          border: 1px solid #bababa;
          border-radius: 8px;
          .BibanVisitor__matched-users__body__items__item__image {
            display: flex;
            align-items: center;
            gap: 16px;
            img {
              width: 48px;
              height: 48px;
              border-radius: 50%;
            }
            .BibanVisitor__matched-users__body__items__item__image__user-info-major {
              display: flex;
              flex-direction: column;
              color: #000000;
              .name {
                font-style: normal;
                font-weight: 600;
                font-size: 15px;
                line-height: 26px;
              }
              .major {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 21px;
              }
            }
          }
          .BibanVisitor__matched-users__body__items__item__info {
            margin-top: 12px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            gap: 8px;
            svg {
              width: 20px;
              height: 20px;
            }
            span {
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 21px;
              color: #000000;
            }
          }
          .BibanVisitor__matched-users__body__items__item__bio {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            text-align: right;
            color: #000000;
          }
        }
      }
      .BibanVisitor__matched-users__body__show-more {
        display: flex;
        justify-content: center;
        padding: 16px;
        button {
          display: flex;
          align-items: center;
          gap: 4px;
          justify-content: center;
          span {
            padding-bottom: 6px;
          }
        }
      }
    }
  }
  .BibanVisitor__register-wrapper {
    margin-inline: 1rem;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    background: #ffffff;

    display: flex;
    flex-direction: column;
    .BibanVisitor__register__header {
      img {
        width: 100%;
        max-width: 221.86px;
      }
      display: flex;
      justify-content: center;
      padding: 48px 10px;
      border-bottom: 1px solid #e9e9e9;
    }
    .BibanVisitor__register__body {
      max-width: 480px;
      padding-inline: 16px;
      margin-inline: auto;
      width: 100%;
      padding-bottom: 2rem;
      min-height: 50vh;

      display: flex;
      gap: 48px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      form {
        width: 100%;
      }
      .otp-input__wrapper {
        display: flex;
        flex-direction: column;
        gap: 48px;
        justify-content: center;
        align-items: center;
        max-width: 300px;
        margin-inline: auto;
        text-align: center;
        h3 {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          color: #111111;
        }
        > div {
          gap: 1rem;
          width: 100%;
          justify-content: center;
          .otp-input {
            input {
              outline: none;
              border: none;
              width: 42px !important;
              height: 48px;
              border: 1px solid #bababa;
              border-radius: 2px;

              text-align: center;
              font-size: 24px;
              line-height: 35px;
              color: #000000;
            }
          }
        }
        .resend {
          cursor: pointer;
          align-self: self-end;
          background-color: unset;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          text-decoration-line: underline;
          color: #595959;

          margin-top: -35px;
          margin-inline-end: 35px;
        }
      }
      .congrats__wrapper {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        align-items: center;
        justify-content: center;
        h4 {
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          line-height: 35px;
          color: #111111;
        }
        p {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          color: #111111;
        }
      }
    }
  }
}
.BibanVisitor__matched-users__modal-header {
  padding-bottom: 32px;
  img {
    width: 100%;
  }
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    text-align: center;

    color: #000000;
    margin-top: 32px;
  }
  > div:first-child {
    border-bottom: 1px solid #bababa50;
  }
  .BibanVisitor__matched-users__modal-header__action {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    gap: 48px;
    justify-content: center;
    align-items: center;
    h3 {
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 35px;
      text-align: center;
      max-width: 426px;
      color: #000000;
    }
    button {
      display: flex;
      align-items: center;
      gap: 8px;
      justify-content: center;
      a {
        padding-bottom: 5px;
        color: #fff;
      }
    }
  }
}
.BibanVisitor__matched-users__qr-modal-header {
  padding-block: 32px;
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 35px;
    text-align: center;
    color: #000000;
  }
}
.BibanVisitor__matched-users__qr-modal-header__action {
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  align-items: center;
  img {
    width: 150px;
    height: 150px;
  }
}
.biban-brand {
  position: fixed;
  bottom: 20px;
  left: 20px;
}
@media screen and (max-width: 1024px) {
  .BibanVisitor__welcome-wrapper {
    position: relative;
  }
  .biban-brand {
    position: absolute;
    bottom: 20px;
    left: 20px;
  }
}
.BibanVisitor__register__body__go-back {
  cursor: pointer;
  margin: 10px;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  gap: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #595959;

  span {
    padding-bottom: 3px;
  }
}
