@font-face {
  font-family: "DINNextLTArabic";
  src: url("../fonts/ArbFONTS-DINNextLTArabic-Bold-2.ttf");
  src: url("../fonts/ArbFONTS-DINNextLTArabic-Regular-2.ttf");
}
#root {
  min-height: 100vh;
}
body {
  direction: rtl;
  // background-color: #f3f3f3;
  min-height: 100vh;
  background: radial-gradient(#f3f3f3, #f3f3f3);
  font-family: "DINNextLTArabic" !important;
}
.App {
  min-height: 100vh;
  height: auto;
  // display: flex;
  // flex-direction: column;
  // >div:first-child{
  //   flex-grow: 1;
  // }
}
a {
  float: right;
}
.App-link {
  color: #61dafb;
}
.ant-notification {
  z-index: 9999999 !important;
}
.ant-notification-notice-closable .ant-notification-notice-message {
  padding-right: 32px;
}
.ant-notification-notice-close {
  display: flex;
  justify-content: flex-end;
  right: 0 !important;
  left: 22px !important;
}
.ant-notification-notice-message {
  padding-right: 32px !important;
}
